import * as React from 'react';
import { SetStateAction, useEffect } from 'react';
import { TFF } from '@tff/types';
import AlternativeFlightCards from './AlternativeFlightCards';
import { AlternativeFlightsFooter } from './AlternativeFlightsFooter';
import BookingCard from '../ConfirmationPage/BookingCard';
import { FlightAlternatives } from '@tff/types/TFF';

interface props {
  journeys: TFF.Journey[];
  flightAlternatives?: FlightAlternatives;
  setRebookStep: (value: 'Search' | 'Alternatives' | 'Validate') => void;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleOfferSelection: (offer: TFF.CombinationOffer) => void;
  setSelectedOfferJourneys: (value: SetStateAction<TFF.Journey[]>) => void;
  setGetOffer: (value: SetStateAction<TFF.CombinationOffer | undefined>) => void;
}

const AlternativeFlightsPage: React.FC<props> = ({
  journeys,
  flightAlternatives,
  setRebookStep,
  rebookCloser,
  handleOfferSelection,
  setSelectedOfferJourneys,
  setGetOffer,
}) => {
  const offers: { [key: string]: TFF.OfferCombinations } = flightAlternatives?.CombinationOffers!;
  const firstCombinationOfferId = Object.keys(offers)[0];
  const firstOffer = flightAlternatives?.CombinationOffers?.[firstCombinationOfferId]?.Offers.sort(
    (a, b) => a.TotalAmount - b.TotalAmount,
  )[0];
  const [selectedOffer, setSelectedOffer] = React.useState<TFF.CombinationOffer | undefined>(firstOffer);

  useEffect(() => {
    setGetOffer(selectedOffer);
  }, [selectedOffer]);

  const handleValidationClick = () => {
    if (selectedOffer) {
      handleOfferSelection(selectedOffer);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (firstCombinationOfferId && firstOffer) {
      // this is to find and set the Journeys of the first offer
      const outboundJourneyId = firstCombinationOfferId.split('-')[0];
      const outboundJourney = flightAlternatives?.Journeys.find(journey => journey.Id === outboundJourneyId);

      const inboundJourneyId = firstCombinationOfferId.split('-')[1];
      const inboundJourney = flightAlternatives?.Journeys.find(journey => journey.Id === inboundJourneyId);
      setSelectedOfferJourneys([
        {
          ...outboundJourney!,
          CabinClass: firstOffer.PriceClasses.Journeys[outboundJourney!.Id],
        },
        ...(journeys.length > 1
          ? [
              {
                ...inboundJourney!,
                CabinClass: firstOffer.PriceClasses.Journeys[inboundJourney!.Id],
              },
            ]
          : []),
      ]);
    }
  }, []);
  return (
    <>
      <div className="tff-original-booking">
        <BookingCard journeys={journeys} isOriginalBooking={true} />
      </div>
      <AlternativeFlightCards
        journeys={journeys}
        flightAlternatives={flightAlternatives}
        selectedOffer={selectedOffer}
        setSelectedOffer={setSelectedOffer}
        setSelectedOfferJourneys={setSelectedOfferJourneys}
      />
      <AlternativeFlightsFooter
        setRebookStep={setRebookStep}
        rebookCloser={rebookCloser}
        handleValidationClick={handleValidationClick}
      />
    </>
  );
};

export default AlternativeFlightsPage;
