import React, { SetStateAction } from 'react';
import { TFF } from '@tff/types';
import BookingCards from './BookingCards';
import NDCCostOverview from './NDCCostOverview';
import { ConfirmationFooter } from './ConfirmationFooter';

interface ConfirmationPageProps {
  originalJourneys: TFF.Journey[];
  newBookingJourneys: TFF.Journey[];
  fareDifference: number;
  taxAmount: number;
  penalty: number;
  baseAmount: number;
  selectedOffer: TFF.CombinationOffer | undefined;
  orderDetails: TFF.OrderDetails;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleConfirmation: (offer: TFF.CombinationOffer) => void;
}

const ConfirmationPage: React.FC<ConfirmationPageProps> = ({
  originalJourneys,
  newBookingJourneys,
  fareDifference,
  taxAmount,
  penalty,
  baseAmount,
  rebookCloser,
  handleConfirmation,
  selectedOffer,
  orderDetails,
}) => {
  const handleConfirmationClick = () => {
    if (selectedOffer) {
      handleConfirmation(selectedOffer);
    }
  };
  return (
    <div>
      <BookingCards
        originalJourneys={originalJourneys}
        newBookingJourneys={newBookingJourneys}
        fareDifference={fareDifference}
      />
      <NDCCostOverview
        originalJourneys={originalJourneys}
        newBookingJourneys={newBookingJourneys}
        fareDifference={fareDifference}
        taxAmount={taxAmount}
        penalty={penalty}
        baseAmount={baseAmount}
        orderDetails={orderDetails}
      />
      <ConfirmationFooter rebookCloser={rebookCloser} handleConfirmationClick={handleConfirmationClick} />
    </div>
  );
};

export default ConfirmationPage;
