
export const commonStyles = {
    tablePaper: {
      marginTop: 3,
      marginLeft: 1,
      padding: 3,
      minWidth: 650,
      width: '100%',
      overflow: 'hidden',
      borderRadius: '8px',
    },
    cellHeader: {
      color: '#4D4D4D',
      fontSize: '18px',
      lineHeight: '20.59px',
      fontWeight: 700,
      width: '320px',
      borderColor: '#737373',
    },
    cellBody: {
      color: '#4D4D4D',
      fontSize: '18px',
      lineHeight: '20.59px',
      fontWeight: 700,
      width: '328px',
      borderColor: '#737373',
    },
    typography: {
      textAlign: 'left',
    },
    tableTitle: {
      fontSize: '24px',
      color: '#1A115A',
      fontWeight: 700,
      lineHeight: '30.89px',
      paddingBottom: 3,
    },
    caption: { display: 'flex', marginTop: '16px', textAlign: 'center', fontSize: '12px', color: '#737373' },
  };