import React, { SetStateAction } from 'react';
import TFFButton from '../../../../styled-components/TFFButton';
import { useIntl } from 'react-intl';

interface props {
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleConfirmationClick: () => void;
}

export const ConfirmationFooter: React.FC<props> = ({ rebookCloser, handleConfirmationClick }) => {
  const intl = useIntl();
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20, marginLeft: 10 }}>
      <TFFButton
        buttonText={intl.formatMessage({ id: 'ndc.rebookCancel' })}
        outline
        variant="secondary"
        handleClick={() => rebookCloser(false)}
      />
      <div>
        <TFFButton
          buttonText={`${intl.formatMessage({ id: 'ndc.rebookAccept' })}`}
          variant="secondary"
          handleClick={() => handleConfirmationClick()}
        />
      </div>
    </div>
  );
};
