import TFFIcon from '../../styled-components/TFFIcon';
import TFFTypography from '../../styled-components/TFFTypography';

interface BackButtonProps {
  handleClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ handleClick }) => {
  return (
    <div className="tff-back-button-container">
      <div className="tff-back-button" onClick={handleClick}>
        <span id="back-button-icon">
          <TFFIcon iconName="arrow-left-circled" />
        </span>
        <TFFTypography text="BACK" variant="lead" />
      </div>
    </div>
  );
};

export default BackButton;
