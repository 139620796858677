import { Grid, Typography } from '@mui/material';
import { useStyles } from './use-styles';
import { FlightIcon } from '../../icons';
import { useIntl } from 'react-intl';

interface props {
  isFirstJourney: boolean;
}

const Header: React.FC<props> = ({ isFirstJourney }) => {
  const classes = useStyles();
  const intl = useIntl();
  const renderFlightIcon = () => {
    return isFirstJourney ? (
      <div className={classes.iconWrapper}>
        <FlightIcon />
      </div>
    ) : (
      <div className={classes.iconWrapperReversed}>
        <FlightIcon />
      </div>
    );
  };
  return (
    <Grid container className={classes.flexCenter}>
      <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {renderFlightIcon()}
        <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
          <strong>
            {isFirstJourney
              ? intl.formatMessage({ id: 'rebook.directionOutbound' })
              : intl.formatMessage({ id: 'rebook.directionInbound' })}
          </strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
