import React from 'react';
import Paper from '@material-ui/core/Paper';
import RebookFlightTrack from '../RebookFlightTrack';
import FlightDetails from './FlightDetails';
import { useStyles } from './use-styles';
import { TFF } from '@tff/types';

interface Props {
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  airports: TFF.IAirport[];
  displayFlights?: boolean;
  displayConfirmation?: boolean;
  retrieveDb?: TFF.Retrieve;
  setEnableSearchAlternatives: (value: boolean) => void;
  setLowerClassForNDC?: (value: boolean) => void;
  setSelectedCabinClassForNDC?: (value: string) => void;
  selectedCabinClassForNDC?: string;
}

const ChangeFlightCard: React.FC<Props> = ({
  journey,
  airports,
  orderDetails,
  displayFlights,
  displayConfirmation,
  retrieveDb,
  setEnableSearchAlternatives,
  setLowerClassForNDC,
  setSelectedCabinClassForNDC,
  selectedCabinClassForNDC,
}) => {
  const classes = useStyles();

  return (
    <Paper
      style={{ display: !displayFlights && !displayConfirmation ? 'block' : 'none' }}
      elevation={2}
      className={classes.root}
    >
      {journey && airports && <RebookFlightTrack journey={journey} airports={airports} orderDetails={orderDetails} />}
      <FlightDetails
        orderDetails={orderDetails}
        journey={journey}
        airports={airports}
        retrieveDb={retrieveDb}
        setEnableSearchAlternatives={setEnableSearchAlternatives}
        setLowerClassForNDC={setLowerClassForNDC}
        setSelectedCabinClassForNDC={setSelectedCabinClassForNDC}
        selectedCabinClassForNDC={selectedCabinClassForNDC}
      />
    </Paper>
  );
};

export default ChangeFlightCard;
