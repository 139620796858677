import React, { SetStateAction } from 'react';
import TFFButton from '../../../../styled-components/TFFButton';
import { useIntl } from 'react-intl';

interface props {
  setRebookStep: (value: 'Search' | 'Alternatives' | 'Validate') => void;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleValidationClick: () => void;
}

export const AlternativeFlightsFooter: React.FC<props> = ({ setRebookStep, rebookCloser, handleValidationClick }) => {
  const intl = useIntl();
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <TFFButton
        buttonText={`${intl.formatMessage({ id: 'ndc.rebookCancel' })}`}
        outline
        variant="secondary"
        handleClick={() => rebookCloser(false)}
      />
      <div style={{ display: 'flex', gap: '16px' }}>
        <TFFButton
          buttonText={`${intl.formatMessage({ id: 'ndc.rebookNewSearch' })}`}
          outline
          variant="secondary"
          handleClick={() => setRebookStep('Search')}
        />
        <TFFButton
          buttonText={`${intl.formatMessage({ id: 'ndc.rebookAccept' })}`}
          variant="secondary"
          handleClick={() => handleValidationClick()}
        />
      </div>
    </div>
  );
};
