import React from 'react';
import { useIntl } from 'react-intl';
import { ServicePackageConnectorResponse } from '../../hooks/use-service-packages';
import TFFIcon from '../../styled-components/TFFIcon';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFAccordionNew from '../../styled-components/TFFAccordionNew';
import { TFF } from '@tff/types';

interface props {
  serviceResponse?: ServicePackageConnectorResponse;
  passengers: TFF.Passenger[];
}

const ServicesCard: React.FC<props> = ({ serviceResponse, passengers }) => {
  const intl = useIntl();

  return (
    <TFFAccordionNew
      accordionId="services-card"
      summaryChildren={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <span>
                <TFFIcon iconName="ticket" color="color-theme-variant" />
              </span>
              <TFFTypography
                variant="lead"
                fontWeight="bold"
                text={intl.formatMessage({ id: 'servicePackages.title' })}
              />
            </div>
            <TFFTypography
              text={serviceResponse?.servicePackages.passengers![0].selectedServicePackage.name!}
              color="color-functional-neutral-900"
            />
          </div>

          <TFFTypography
            color="color-theme-variant"
            fontWeight="bold"
            text={
              serviceResponse?.servicePackages.passengers === undefined
                ? intl.formatNumber(0, { style: 'currency', currency: 'EUR' })
                : intl.formatNumber(
                  passengers.length *
                  serviceResponse?.servicePackages.passengers[0].selectedServicePackage.price.value!,
                  { style: 'currency', currency: 'EUR' },
                )
            }
          />
        </div>
      }
      detailsChildren={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: '4px',
            minHeight: '250px',
            width: '100%',
          }}
        >
          {serviceResponse?.servicePackages.passengers === undefined ? null : (
            <>
              <div style={{ color: '#09295D', marginLeft: '50px', marginTop: '17px' }}>
                <TFFTypography
                  text={`${serviceResponse?.servicePackages.passengers[0].selectedServicePackage.name} - ${passengers.length} passenger(s) x €${serviceResponse?.servicePackages.passengers[0].selectedServicePackage.price.value}`}
                  fontWeight="bold"
                  color="color-functional-neutral-900"
                ></TFFTypography>
              </div>

              <div style={{ marginLeft: '60px', marginTop: '17px' }}>
                {Array.from(
                  new Set(
                    serviceResponse?.servicePackages.passengers[0].selectedServicePackage.serviceConfigs
                      .filter(services => services.value === 'true')
                      .map(services => services.service.name),
                  ),
                ).map((serviceName, index) => (
                  <li key={index} style={{ fontSize: '18px' }}>
                    {serviceName}
                  </li>
                ))}
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default ServicesCard;
