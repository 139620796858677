import React from 'react';
import AncillaryRow from './AncillaryRow';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFDivider from '../../styled-components/TFFDivider';

interface props {
  passenger: TFF.Passenger;
  passengerNr: number;
  ancillaries: TFF.Ancillaries;
  isOutbound: boolean;
}

const PassengerRow: React.FC<props> = ({ isOutbound, passengerNr, passenger, ancillaries }) => {
  const intl = useIntl();

  const isInfantPossible =
    (passenger.Type !== 'INF' && ancillaries[passenger?.JourneyExtras![isOutbound ? 0 : 1]?.Infant!]) || null;

  const isSeatPossible =
    (passenger.Type !== 'INF' && ancillaries[passenger?.JourneyExtras![isOutbound ? 0 : 1]?.Seat!]) || null;

  const isSsrPossible = (passenger.Type !== 'INF' && passenger.JourneyExtras![isOutbound ? 0 : 1].Ssrs?.length) || null;
  return (
    <div>
      <div style={{ marginBottom: '4px' }}>
        <TFFTypography
          text={` ${intl.formatMessage({ id: 'ancillaries.passenger' })} ${
            passenger.Type == 'INF' ? passengerNr + '_Inf' : passengerNr
          } - ${passenger.FirstName}
              ${passenger.LastName}`}
          color="color-concept-tuiblue"
          fontWeight="semibold"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        {passenger.Type === 'INF' ? (
          <TFFTypography text="Infant, no seat or baggage assigned." color="color-functional-neutral-600" />
        ) : (
          <>
            {!isSsrPossible && !isSeatPossible ? (
              <TFFTypography text="Nothing selected" color="color-functional-neutral-600" />
            ) : (
              passenger.JourneyExtras![isOutbound ? 0 : 1].Ssrs?.map((ssr, index) =>
                ancillaries[ssr].Description ? (
                  <AncillaryRow key={index} ancillary={ancillaries[ssr]} isOutbound={isOutbound} />
                ) : null,
              )
            )}
          </>
        )}
        {(isInfantPossible || isSeatPossible) && (
          <>
            {passenger.JourneyExtras![isOutbound ? 0 : 1].Ssrs?.length! > 0 && <TFFDivider />}
            {isInfantPossible && (
              <AncillaryRow
                ancillary={ancillaries[passenger?.JourneyExtras![isOutbound ? 0 : 1]?.Infant!]}
                isOutbound={isOutbound}
              />
            )}
            {isSeatPossible && (
              <AncillaryRow
                ancillary={ancillaries[passenger?.JourneyExtras![isOutbound ? 0 : 1]?.Seat!]}
                isOutbound={isOutbound}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PassengerRow;
