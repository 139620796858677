import * as React from 'react';
import { useState, SetStateAction, useEffect } from 'react';

import * as TFF from '@tff/types/TFF';
import SearchAlternativeFlights from './SearchAlternativeFlights';
import AlternativeFlightsPage from './AlternativeFlightsPage';
import { useAmendBooking } from '../../../hooks/use-amend-booking';
import ConfirmationPage from './ConfirmationPage';
import TFFDialog from '../../../styled-components/TFFDialog';
import TFFTypography from '../../../styled-components/TFFTypography';
import TFFAlert from '../../../styled-components/TFFAlert';
import { useIntl } from 'react-intl';
import BackButton from '../BackButton';
import { FlightAlternatives } from '@tff/types/TFF';

export type SelectedAmendBookings = {
  combiHashCodes: string[];
  journeyONDs: string[];
  offerPrices: string[];
};

interface props {
  orderDetails: TFF.OrderDetails;
  airports: TFF.IAirport[];
  recordLocator: string;
  selectedAmendBookings: SelectedAmendBookings;
  setSelectedAmendBookings: (value: SetStateAction<SelectedAmendBookings>) => void;
  tfmPnr: string | undefined;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  flightDetails: TFF.FlightDetails;
  midocoOrderNo?: number;
  cancelHandler: () => void;
  retrieveDb?: TFF.Retrieve;
}

type rebookStep = 'Search' | 'Alternatives' | 'Validate';

const NdcRebook: React.FC<props> = ({
  orderDetails,
  airports,
  recordLocator,
  selectedAmendBookings,
  setSelectedAmendBookings,
  tfmPnr,
  rebookCloser,
  flightDetails,
  midocoOrderNo,
  cancelHandler,
  retrieveDb,
}) => {
  const ndcRebookSteps: rebookStep[] = ['Search', 'Alternatives', 'Validate'];
  const [rebookStep, setRebookStep] = useState<rebookStep>('Search');
  const { setAmendBookingRequest, amendResponse, amendStatus, amendError } = useAmendBooking();
  const [selectedOfferJourneys, setSelectedOfferJourneys] = useState<TFF.Journey[]>([]);
  const [showError, setShowError] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [isOnConfirmation, setIsOnConfirmation] = useState<boolean>(false);
  const handleFinishSearch = () => {
    setRebookStep('Alternatives');
  };
  const [getOffer, setGetOffer] = React.useState<TFF.CombinationOffer | undefined>();
  const [showConfirmationError, setShowConfirmationError] = React.useState<boolean>(false);
  const [alternativesRequestQuery, setAlternativesRequestQuery] = React.useState<TFF.AmendBookingRequest>();
  const [currentAlternativeFlights, setCurrentAlternativeFlights] = React.useState<FlightAlternatives>();
  const intl = useIntl();

  useEffect(() => {
    if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'search-alternatives') {
      setAlternativesRequestQuery(amendResponse?.query);
      setCurrentAlternativeFlights(amendResponse?.flightAlternatives);
      setRebookStep('Alternatives');
    }
    //@ts-ignore
    if (amendStatus === 'FAILURE' && amendResponse?.query.action === 'validate') {
      setRebookStep('Search');
      setShowError(true);
    } else if (amendStatus === 'SUCCESS' && rebookStep === 'Validate' && isOnConfirmation) {
      setShowValidation(true);
    } else if (amendStatus === 'FAILURE' && amendResponse?.query.action === 'confirm') {
      setShowConfirmationError(true);
    }
  }, [amendStatus]);

  const handleBackButton = () => {
    const currentStepIndex = ndcRebookSteps.indexOf(rebookStep);
    if (currentStepIndex !== -1) {
      if (rebookStep === 'Search') {
        rebookCloser(false);
      } else {
        const previousRebookStep = ndcRebookSteps[currentStepIndex - 1];
        setRebookStep(previousRebookStep);
      }
    }
  };

  const handleOfferSelection = async (offer: TFF.CombinationOffer) => {
    const alternativeOfferRequest: TFF.AmendBookingRequest = {
      action: 'validate',
      offerId: offer.Id,
      paxData: amendResponse?.query?.paxData,
      recordLocator: recordLocator,
      tfmPnr: amendResponse?.query?.tfmPnr!,
      sessionId: amendResponse?.sessionId,
      bookingSource: amendResponse?.query?.bookingSource!,
    };
    setAmendBookingRequest(alternativeOfferRequest);
    setRebookStep('Validate');
  };

  const handleOfferConfirmation = async (offer: TFF.CombinationOffer) => {
    const alternativeOfferRequest: TFF.AmendBookingRequest = {
      action: 'confirm',
      recordLocator: recordLocator,
      tfmPnr: amendResponse?.query?.tfmPnr!,
      bookingSource: amendResponse?.query?.bookingSource!,
      offerId: amendResponse?.ndcValidationResponse?.offerId,
      //@ts-ignore
      combinations: [
        {
          AdditionalParams: {
            ...amendResponse?.ndcValidationResponse?.paxOfferItemIds,
            OfferId: amendResponse?.ndcValidationResponse?.offerId,
            OfferItemIds: amendResponse?.ndcValidationResponse?.offerItemIds.join(','),
          },
        },
      ],
    };
    setAmendBookingRequest(alternativeOfferRequest);
    setIsOnConfirmation(true);
  };

  const handleShowScreenByStep = () => {
    switch (rebookStep) {
      case 'Search':
        return (
          <>
            {showError && (
              <TFFDialog
                dialogId="validationError"
                header={<TFFTypography text="Error" />}
                isOpen={showError}
                children={<div>Connection Error. Please try again</div>}
                closeButton
                onCancel={() => setShowError(false)}
              />
            )}
            <SearchAlternativeFlights
              orderDetails={orderDetails}
              journeys={orderDetails.Journeys}
              airports={airports}
              recordLocator={recordLocator}
              selectedAmendBookings={selectedAmendBookings}
              setSelectedAmendBookings={setSelectedAmendBookings}
              tfmPnr={tfmPnr}
              rebookCloser={rebookCloser}
              flightDetails={flightDetails}
              midocoOrderNo={midocoOrderNo}
              cancelHandler={cancelHandler}
              retrieveDb={retrieveDb}
              searchFinished={handleFinishSearch}
              setAmendBookingRequest={setAmendBookingRequest}
              amendResponse={amendResponse}
              amendStatus={amendStatus}
              amendError={amendError}
            />
          </>
        );
      case 'Alternatives':
        return (
          <AlternativeFlightsPage
            journeys={orderDetails.Journeys}
            setRebookStep={setRebookStep}
            rebookCloser={rebookCloser}
            flightAlternatives={currentAlternativeFlights}
            handleOfferSelection={handleOfferSelection}
            setSelectedOfferJourneys={setSelectedOfferJourneys}
            setGetOffer={setGetOffer}
          />
        );
      case 'Validate':
        return (
          <>
            {showConfirmationError && (
              <TFFDialog
                dialogId="validationError"
                header={
                  <TFFTypography
                    text="Amend notification"
                    fontWeight="bold"
                    extraStyle={{
                      fontWeight: 700,
                      fontSize: '24px',
                      lineHeight: '27px',
                      font: 'Ambit',
                      color: '#1A115A',
                    }}
                  />
                }
                isOpen={showConfirmationError}
                children={<TFFAlert variant="error" text={intl.formatMessage({ id: 'rebook.rebookError' })} />}
                cancelButton
                confirmButton
                onCancel={() => rebookCloser(false)}
                onConfirm={() => {
                  setShowConfirmationError(false);
                  setAmendBookingRequest(alternativesRequestQuery!);
                }}
                confirmButtonText={intl.formatMessage({ id: 'rebook.retry' })}
              />
            )}
            {showValidation && (
              <TFFDialog
                dialogId="validation"
                header={
                  <TFFTypography
                    text="Amend notification"
                    fontWeight="bold"
                    extraStyle={{
                      fontWeight: 700,
                      fontSize: '24px',
                      lineHeight: '27px',
                      font: 'Ambit',
                      color: '#1A115A',
                    }}
                  />
                }
                isOpen={showValidation}
                children={<TFFAlert variant="success" text={intl.formatMessage({ id: 'ndc.rebookAlert' })} />}
                closeButton
                onCancel={() => {
                  setIsOnConfirmation(false);
                  setShowValidation(false);
                  window.location.reload();
                }}
                closeButtonText={intl.formatMessage({ id: 'ndc.rebookReloadPnr' })}
              />
            )}
            <ConfirmationPage
              originalJourneys={orderDetails.Journeys}
              newBookingJourneys={selectedOfferJourneys}
              fareDifference={amendResponse?.ndcValidationResponse?.amendTotalCost ?? 0}
              //@ts-ignore
              taxAmount={amendResponse?.ndcValidationResponse?.amendCostBreakdown.taxSummary}
              //@ts-ignore
              penalty={amendResponse?.ndcValidationResponse?.amendCostBreakdown.fee}
              //@ts-ignore
              baseAmount={amendResponse?.ndcValidationResponse?.amendCostBreakdown.baseAmount}
              rebookCloser={rebookCloser}
              handleConfirmation={handleOfferConfirmation}
              selectedOffer={getOffer}
              orderDetails={orderDetails}
            />
          </>
        );
    }
  };

  return (
    <>
      <BackButton handleClick={handleBackButton} />
      {handleShowScreenByStep()}
    </>
  );
};

export default NdcRebook;
