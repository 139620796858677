import React from 'react';
import { PriceClasses, Segment } from '@tff/types/TFF';
import TFFIcon from '../../../../styled-components/TFFIcon';
import TFFTypography from '../../../../styled-components/TFFTypography';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';

interface FarePerSegmentProps {
  segments: Segment[];
  prices: PriceClasses;
  isOutbound?: boolean;
  ondClassAndFare?: string;
}

const FarePerSegment: React.FC<FarePerSegmentProps> = ({ segments, prices, isOutbound = false, ondClassAndFare }) => {
  const ondClass = ondClassAndFare?.split(' ')[0];
  const intl = useIntl();
  return (
    <>
      {segments.length > 0 && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>
            <span>
              <TFFIcon iconName={isOutbound ? 'aircraft-right' : 'aircraft-left'} color="color-theme-variant" />
            </span>
          </div>
          <div style={{ marginBottom: '8px' }}>
            {segments.map((segment, index) => {
              const segmentId = segment.Id;
              const segmentClassAndFare = prices.Segments?.[segmentId].Name
              const segmentClass = segmentClassAndFare?.split(' ')[0];
              return (
                <div style={{ display: 'flex', gap: '8px', marginBottom: '4px', alignItems: 'center' }}>
                  <TFFTypography text={segment.Ond} color="color-functional-neutral-900" />
                  <TFFTypography
                    text={segmentClassAndFare}
                    color="color-functional-neutral-900"
                    fontWeight="bold"
                  />
                  {ondClass !== segmentClass && <Tooltip title={intl.formatMessage({ id: "rebook.mixedCabinClassTooltip" })} arrow>{/* We should convert this to TUI-Light */}
                    <span>
                      <TFFIcon size="small" iconName="letter-i-circled" color="color-theme-contrast" />
                    </span>
                  </Tooltip>}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default FarePerSegment;
