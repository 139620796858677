import React from 'react';
import { TFF } from '@tff/types';
import BookingCard from './BookingCard';

interface BookingCardsProps {
  originalJourneys: TFF.Journey[];
  newBookingJourneys?: TFF.Journey[];
  fareDifference?: number;
}

const BookingCards: React.FC<BookingCardsProps> = ({ originalJourneys, newBookingJourneys, fareDifference }) => {
  const compareJourneys = (journey1: TFF.Journey, journey2: TFF.Journey) => {
    return (
      journey1.Segments.every((segment, index) => {
        const segment2 = journey2.Segments[index];
        return (
          segment.Origin === segment2.Origin &&
          segment.Destination === segment2.Destination &&
          segment.ArrivalTime === segment2.ArrivalTime &&
          segment.DepartureTime === segment2.DepartureTime
        );
      })
    )
  }

  return (
    <div>
      <div className="tff-original-booking">
        <BookingCard journeys={originalJourneys} isOriginalBooking={true} />
      </div>
      {newBookingJourneys && (
        <div className="tff-new-booking">
          <BookingCard
            journeys={newBookingJourneys}
            isOriginalBooking={false}
            fareDifference={fareDifference}
            isOutboundDifferent={!compareJourneys(originalJourneys[0], newBookingJourneys[0])}
            isInboundDifferent={originalJourneys.length > 1 ? !compareJourneys(originalJourneys[1], newBookingJourneys[1]) : false}
          />
        </div>
      )}
    </div>
  );
};

export default BookingCards;
