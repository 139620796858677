import React from 'react';
import { AmendJourney, PriceClasses } from '@tff/types/TFF';
import TFFTypography from '../../../../styled-components/TFFTypography';
import { CombinationOffer } from '@tff/types/TFF/tff-flight-details';
import FarePerSegment from './FarePerSegment';
import TFFRadioAccordion from '../../../../styled-components/TFFRadioAccordion';
import TFFIcon from '../../../../styled-components/TFFIcon';
import { useIntl } from 'react-intl';

interface AlternativeFlightFaresProps {
  outboundJourney: AmendJourney;
  inboundJourney?: AmendJourney;
  fareOffers: CombinationOffer[];
  selectedOffer: CombinationOffer | undefined;
  handleOfferClick: (offer: CombinationOffer) => void;
}

const AlternativeFlightFares: React.FC<AlternativeFlightFaresProps> = ({
  outboundJourney,
  inboundJourney,
  fareOffers,
  selectedOffer,
  handleOfferClick,
}) => {
  const hasMoreThanThreeOffers = fareOffers!.length > 3;
  const [filteredOffers, setFilteredOffers] = React.useState(
    hasMoreThanThreeOffers ? fareOffers.slice(0, 3) : fareOffers,
  );
  const [showMoreIsOpen, setShowMoreIsOpen] = React.useState(false);
  const intl = useIntl();

  const outboundJourneyId = outboundJourney.Id;
  const inboundJourneyId = inboundJourney?.Id!;
  const outboundSegments = outboundJourney?.Segments ?? [];
  const inboundSegments = inboundJourney?.Segments ?? [];

  const journeyClassAndFare = (prices: PriceClasses) => {
    const firstOutboundSegmentId = outboundSegments[0]?.Id;
    const outboundClassAndFare = `${prices.Journeys?.[outboundJourneyId]} ${
      prices.Segments?.[firstOutboundSegmentId].Name.split(' ')[1]
    }`;
    const firstInboundSegmentId = inboundSegments[0]?.Id;
    const inboundClassAndFare = inboundJourney
      ? `${prices.Journeys?.[inboundJourneyId]} ${prices.Segments?.[firstInboundSegmentId].Name.split(' ')[1]}`
      : '';

    return inboundJourney ? `${outboundClassAndFare} + ${inboundClassAndFare}` : `${outboundClassAndFare} `;
  };

  const handleShowMoreButton = () => {
    if (showMoreIsOpen) {
      setFilteredOffers(fareOffers.slice(0, 3));
    } else {
      setFilteredOffers(fareOffers);
    }

    setShowMoreIsOpen(prevState => !prevState);
  };

  const selectedOfferPjId = Object.keys(selectedOffer?.PriceClasses.Journeys!).join('-');

  return (
    <div>
      <TFFTypography
        text={`${intl.formatMessage({ id: 'ndc.rebookFares' })}`}
        color="color-theme-variant"
        fontWeight="bold"
        size="large"
      />
      <br />
      <fieldset className="input-radio-group">
        {filteredOffers?.map((offer, index) => {
          const prices: PriceClasses = offer.PriceClasses;
          const classAndFare = journeyClassAndFare(prices);
          const outboundClassAndFare = classAndFare.split(' + ')[0];
          let inboundClassAndFare = '';
          if (inboundJourney) {
            inboundClassAndFare = classAndFare.split(' + ')[1];
          }
          return (
            <TFFRadioAccordion
              key={index}
              inputId={`ndc-rebook-${outboundJourneyId}-${inboundJourneyId}`}
              index={index}
              radioText={journeyClassAndFare(prices)}
              details={
                <div>
                  <FarePerSegment
                    segments={outboundSegments}
                    prices={prices}
                    isOutbound
                    ondClassAndFare={outboundClassAndFare}
                  />
                  <FarePerSegment segments={inboundSegments} prices={prices} ondClassAndFare={inboundClassAndFare} />
                </div>
              }
              handleRadioClick={() => handleOfferClick({ ...offer })}
              checked={selectedOffer ? selectedOffer.Id === offer.Id : false}
              extraStyles={{
                backgroundColor:
                  selectedOfferPjId === Object.keys(offer.PriceClasses.Journeys!).join('-')
                    ? 'var(--color-theme-base-200)'
                    : ``,
              }}
              iconTextStyles={`${
                selectedOffer!.Id === offer.Id ? `color-functional-base-600` : `color-functional-neutral-900`
              }`}
              iconText={offer.TotalAmount}
            />
          );
        })}
        {hasMoreThanThreeOffers && (
          <button className="text link" style={{ color: 'var(--color-theme-contrast)' }} onClick={handleShowMoreButton}>
            {showMoreIsOpen ? 'Show Less' : 'Show More'}
            <span className="icon-trailing">
              <TFFIcon iconName={showMoreIsOpen ? 'chevron-up' : 'chevron-down'} />
            </span>
          </button>
        )}
      </fieldset>
    </div>
  );
};

export default AlternativeFlightFares;
