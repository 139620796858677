import { TFF } from '@tff/types';

/*
  Configures and forms "ssrsToAdd" state in AncillariesPage.tsx
  fetches already included ssrs from flightDetails and stores them in the state
  if no baggage included, adds a mockup ssr "BA0" to state.
*/

export const formSsrs = (ssrs: TFF.SsrDetails[], pax: TFF.Passenger, flightDetails: TFF.FlightDetails) => {
  for (let i = 0; i < 2; i++) {
    let hasBA = false;
    for (let j = 0; j < pax.JourneyExtras?.[i]?.Ssrs?.length!; j++) {
      const ancillaryId = pax.JourneyExtras?.[i]?.Ssrs?.[j]!;
      const ancillary = flightDetails.OrderDetails.Ancillaries?.[ancillaryId];
      if (!ancillary) {
        continue;
      }

      if (ancillary.Value?.startsWith('BA')) {
        hasBA = true;
      }

      ssrs.push({
        code: ancillary.TfaDetails?.Code ?? ancillary.Value ?? ancillary.Type,
        amount: 1,
        segmentOnd: flightDetails.OrderDetails.Journeys?.[i]?.Ond!,
        price: ancillary.TotalAmount,
        tuiRelated: ancillary.Note === 'ATCOM',
      });
    }

    if (!hasBA) {
      ssrs.push({
        code: 'BA0',
        amount: 1,
        segmentOnd: flightDetails.OrderDetails.Journeys?.[i]?.Ond!,
        tuiRelated: false,
      });
    }
  }
};
