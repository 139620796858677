import { TFF } from '@tff/types';
import { NdcFlightPath } from './NdcFlightPath';
import React from 'react';

interface props {
  firstJourney: TFF.Journey;
  secondJourney?: TFF.Journey;
  outboundCabinClass: string;
  inboundCabinClass: string;
}

export const NdcFlightDetails: React.FC<props> = ({
  firstJourney,
  secondJourney,
  outboundCabinClass,
  inboundCabinClass,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '64px',
        paddingTop: '16px',
        marginLeft: '92px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {firstJourney?.Segments.map((i, index) => (
          <NdcFlightPath
            segment={i}
            key={index}
            index={index}
            stops={firstJourney.Stops}
            /* @ts-ignore */
            stopDuration={firstJourney.StayDuration}
            fareOrClass={outboundCabinClass}
            originalFlight
          />
        ))}
      </div>
      {secondJourney && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {secondJourney.Segments.map((i, index) => (
            <NdcFlightPath
              segment={i}
              key={index}
              index={index}
              stops={secondJourney.Stops}
              /* @ts-ignore */
              stopDuration={secondJourney.StayDuration}
              fareOrClass={inboundCabinClass}
              originalFlight
            />
          ))}
        </div>
      )}
    </div>
  );
};
