import { AmendBookingResponse, FlightAlternatives } from '@tff/types/TFF';
import AlternativeFlightCard from './AlternativeFlightCard';
import React, { SetStateAction } from 'react';
import TFFTypography from '../../../../styled-components/TFFTypography';
import { CombinationOffer } from '@tff/types/TFF/tff-flight-details';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';

interface props {
  journeys: TFF.Journey[];
  flightAlternatives?: FlightAlternatives;
  selectedOffer: CombinationOffer | undefined;
  setSelectedOffer: (offer: CombinationOffer) => void;
  setSelectedOfferJourneys: (value: SetStateAction<TFF.Journey[]>) => void;
}

const AlternativeFlightCards: React.FC<props> = ({
  journeys,
  flightAlternatives,
  selectedOffer,
  setSelectedOffer,
  setSelectedOfferJourneys,
}) => {
  const intl = useIntl();
  //TODO - this is the case where the BE retrieves an failure response, in which case we will have a future ticket to tackle this
  if (!flightAlternatives) {
    return (
      <div>
        No alternative flights to show for now. Note: this is the case where the BE retrieves an failure response, in
        which case we will have a future ticket to tackle this
      </div>
    );
  }

  const compareJourneys = (journey1: TFF.Journey, journey2: TFF.AmendJourney) => {
    return journey1.Segments.every((segment, index) => {
      const segment2 = journey2.Segments[index];
      return (
        segment.Origin === segment2.Origin &&
        segment.Destination === segment2.Destination &&
        segment.ArrivalTime === segment2.ArrivalTime &&
        segment.DepartureTime === segment2.DepartureTime
      );
    });
  };

  const journeyMap = new Map(flightAlternatives?.Journeys.map(journey => [journey.Id, journey]));

  const filteredCombinationOffers = Object.entries(flightAlternatives?.CombinationOffers || {}).filter(
    ([combinationOfferKey]) => {
      const [outboundJourneyId, inboundJourneyId] = combinationOfferKey.split('-');
      const outboundJourney = journeyMap.get(outboundJourneyId);
      const inboundJourney = journeyMap.get(inboundJourneyId);

      return (
        outboundJourney &&
        (!compareJourneys(journeys[0], outboundJourney) || !compareJourneys(journeys[1], inboundJourney!))
      );
    },
  );

  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '16px 0' }}>
        <TFFTypography
          text={`${intl.formatMessage({ id: 'ndc.rebookAlternativeFlightResults' })}`}
          modifier="uppercase"
          color="color-theme-variant"
          fontWeight="bold"
          size="large"
        />
      </div>
      {filteredCombinationOffers.map(([combinationOfferKey, offerData]) => {
        const [outboundJourneyId, inboundJourneyId] = combinationOfferKey.split('-');
        const outboundJourney = journeyMap.get(outboundJourneyId);
        const inboundJourney = journeyMap.get(inboundJourneyId);

        const fareOffers: CombinationOffer[] | undefined =
          flightAlternatives?.CombinationOffers?.[combinationOfferKey]?.Offers;

        return outboundJourney ? (
          <AlternativeFlightCard
            key={combinationOfferKey}
            outboundJourney={outboundJourney}
            inboundJourney={inboundJourney}
            fareOffers={fareOffers?.sort((a, b) => a.TotalAmount - b.TotalAmount)}
            selectedOffer={selectedOffer}
            handleOfferClick={(offer: CombinationOffer) => {
              setSelectedOffer(offer);
              setSelectedOfferJourneys(
                inboundJourney
                  ? [
                      {
                        ...outboundJourney,
                        CabinClass: offer.PriceClasses.Journeys[outboundJourney.Id],
                      },
                      {
                        ...inboundJourney!,
                        CabinClass: offer.PriceClasses.Journeys[inboundJourney!.Id],
                      },
                    ]
                  : [
                      {
                        ...outboundJourney,
                        CabinClass: offer.PriceClasses.Journeys[outboundJourney.Id],
                      },
                    ],
              );
            }}
          />
        ) : null;
      })}
    </div>
  );
};

export default AlternativeFlightCards;
