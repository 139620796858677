import React from 'react';
import TFFAccordion from '../../../../styled-components/TFFAccordion';
import TFFTypography from '../../../../styled-components/TFFTypography';
import FlightStopsPath from '../FlightStopsPath';
import { NdcFlightDetails } from '../NdcFlightDetails';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';

interface BookingCardProps {
    journeys: TFF.Journey[];
    isOriginalBooking: boolean;
    fareDifference?: number;
    isOutboundDifferent?: boolean;
    isInboundDifferent?: boolean;
}

const BookingCard: React.FC<BookingCardProps> = ({ journeys, isOriginalBooking, fareDifference, isOutboundDifferent, isInboundDifferent }) => {
    const intl = useIntl();
    const rebookTitleSplit = intl.formatMessage({ id: isOriginalBooking ? 'rebook.Original' : 'rebook.newOffer' }).split(' ');
    const capitalizeField = value => {
        const unformattedName = value;
        const lowerCase = unformattedName.toLowerCase();
        return unformattedName.charAt(0).toUpperCase() + lowerCase.slice(1);
    };
    const hasSecondJourney = journeys.length > 1;
    const secondJourney = journeys[journeys.length - 1];
    const firstJourney = journeys[0];
    return (
        <div>
            <div style={{ display: 'flex', marginBottom: '16px', padding: '16px 16px 0 16px' }}>
                <div>
                    <TFFTypography
                        text={rebookTitleSplit[0]}
                        fontWeight="bold"
                        color="color-theme-variant"
                        modifier="uppercase"
                    />
                    <TFFTypography
                        text={rebookTitleSplit[1]}
                        fontWeight="bold"
                        color="color-theme-variant"
                        modifier="uppercase"
                    />
                </div>
                <div className="tff-inline-paths">
                    <FlightStopsPath
                        journey={firstJourney}
                        capitalizedCabinClass={capitalizeField(firstJourney.CabinClass)}
                        icon="aircraft-right"
                        isFlightDifferent={isOutboundDifferent}
                    />
                    {hasSecondJourney && (
                        <FlightStopsPath
                            journey={secondJourney}
                            capitalizedCabinClass={capitalizeField(secondJourney.CabinClass)}
                            icon="aircraft-left"
                            isFlightDifferent={isInboundDifferent}
                        />
                    )}
                </div>
            </div>
            {fareDifference && <TFFTypography text={`${intl.formatMessage({ id: 'rebook.totalAmount' }).toUpperCase()}: +${intl.formatNumber(fareDifference, { style: 'currency', currency: 'EUR' })}`} color='color-theme-variant' fontWeight="bold" extraStyle={{ justifySelf: 'right', marginRight: '20px' }} />}            <TFFAccordion
                text={intl.formatMessage({ id: 'pages.flightDetails.flightDetails' })}
                details={
                    <NdcFlightDetails
                        firstJourney={firstJourney}
                        secondJourney={hasSecondJourney ? secondJourney : undefined}
                        outboundCabinClass={capitalizeField(firstJourney.CabinClass)}
                        inboundCabinClass={capitalizeField(secondJourney.CabinClass)}
                    />
                }
            />
        </div>
    );
};

export default BookingCard;