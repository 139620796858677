import * as React from 'react';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import { keycloak } from '../../keycloak';
import { useState } from 'react';
import TFFTab from '../../styled-components/TFFTab';

interface props {
  orderSummary: TFF.OrderSummary;
  product?: TFF.TypeOfProduct;
  hasFlightChanges: boolean;
  handleMenuClicked: (value: string) => void;
  disableButtons: boolean;
  isShrunk: boolean;
  activeButton: string;
  flightRetrieve?: TFF.Retrieve;
}

const FlightMenu: React.FC<props> = ({
  handleMenuClicked,
  orderSummary,
  hasFlightChanges,
  disableButtons,
  isShrunk = false,
  product,
  activeButton,
  flightRetrieve,
}) => {
  const intl = useIntl();

  const isIntern = () => {
    const internalRoles = [
      'Role_IT_Administrator',
      'Role_Business_Administrator',
      'Role_Service_Center_Agent_Intern',
      'Role_Service_Center_Supervisor_Intern',
    ];
    let isIn = false;
    internalRoles.forEach(internRole => {
      if (keycloak.hasRealmRole(internRole)) {
        isIn = true;
      }
      return false;
    });
    return isIn;
  };

  const isItAdmin = keycloak.hasRealmRole('Role_IT_Administrator');
  const isBusinessAdmin = keycloak.hasRealmRole('Role_Business_Administrator');
  const hasNLRole = keycloak.hasRealmRole('M_NL');
  const hasDERole = keycloak.hasRealmRole('M_DE');

  const [isOcnClicked, setIsOcnClicked] = useState<boolean>(false);

  const menuButtons = [
    {
      id: 'notice',
      name: `${intl.formatMessage({ id: 'menu.notice' })}`,
      disable: false,
    },
    {
      id: 'rebook',
      name: `${intl.formatMessage({ id: 'menu.changeBooking' })}`,
      disable: false,
      condition: () => {
        const bookingSources = window.ENV?.ENABLE_NDC_REBOOK ? ['NSK', 'AF', 'KL'] : ['NSK'];
        return window.ENV?.ENABLE_REBOOK === true && bookingSources.includes(flightRetrieve?.bookingSource ?? '');
      },
    },
    {
      id: 'cancelBooking',
      name: `${intl.formatMessage({ id: 'menu.cancelBooking' })}`,
      disable:
        (orderSummary?.BookingStatus === 'Closed' ?? true) ||
        orderSummary.BookingStatus.toLowerCase() === 'cancelled' ||
        disableButtons,
    },
    {
      id: 'history',
      name: `${intl.formatMessage({ id: 'menu.history' })}`,
      disable: false,
    },
    {
      id: 'changes',
      name: `${intl.formatMessage({ id: 'menu.changes' })}`,
      disable: !hasFlightChanges,
    },
    {
      id: 'triggerPNRchangeToAcr',
      name: `${intl.formatMessage({ id: 'menu.pnrChangeTriggerToAcr' })}`,
      disable: false,
      condition: () => {
        return (
          product &&
          ((product === 'aircruiser' && (hasNLRole || isIntern())) ||
            (product === 'atcomres' && (hasDERole || isIntern())))
        );
      },
    },
    {
      id: 'ocnman',
      name: `${intl.formatMessage({ id: 'menu.ocnman' })}`,
      disable:
        (orderSummary?.BookingStatus === 'Closed' ?? true) ||
        orderSummary.BookingStatus.toLowerCase() === 'cancelled' ||
        (!isItAdmin && !isBusinessAdmin) ||
        isOcnClicked,
      condition: () => {
        return orderSummary?.SourceDetails && orderSummary.SourceDetails.System === 'TUI-NDC';
      },
    },
  ];

  return (
    <div className="tff-menuWrapper tabs">
      <div className="tff-menuButtonsWrapper" role="tablist">
        {menuButtons
          .filter(btn => {
            //skip false condition buttons
            return btn.condition === undefined || btn.condition();
          })
          .map((tab, index) => {
            return (
              <TFFTab
                key={index}
                tabName={tab.name}
                extraClassName="tff-flightMenuButtons"
                handleClick={() => {
                  handleMenuClicked(tab.id);
                  if (!isShrunk) {
                    setIsOcnClicked(true);
                  }
                }}
                disabled={tab.disable}
                extraStyles={{
                  color: tab.disable ? 'var(--color-functional-neutral-600)' : 'var(--color-functional-neutral-900)',
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default FlightMenu;
